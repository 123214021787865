import { Buffer } from 'buffer';
import CryptoES from 'crypto-es';

import envConstant from '../../internals/env/env_constants.json';

const b64ToUTF8 = (str: string) => {
  return Buffer.from(str, 'base64').toString('utf8');
};

const UTFKey = CryptoES.enc.Utf8.parse(b64ToUTF8(envConstant.DEFAULT_PASS_KEY));
const UTFIV = CryptoES.enc.Utf8.parse(b64ToUTF8(envConstant.DEFAULT_PASS_IV));

export const encryptData = (data: any) => {
  const fixedData = typeof data === 'string' ? data : JSON.stringify(data);
  const encrypted = CryptoES.AES.encrypt(fixedData, UTFKey, {
    // @ts-ignore
    iv: UTFIV,
    format: CryptoES.format.Hex,
    mode: CryptoES.mode.CBC,
    padding: CryptoES.pad.Pkcs7,
  });

  return encrypted.toString();
};

export const decryptData = (data: any) => {
  const decrypted = CryptoES.AES.decrypt(data, UTFKey, {
    iv: UTFIV,
    format: CryptoES.format.Hex,
    mode: CryptoES.mode.CBC,
    padding: CryptoES.pad.Pkcs7,
  });
  return JSON.parse(decrypted.toString(CryptoES.enc.Utf8));
};

export const getClientId = (clientId: string | undefined) => {
  return clientId || getSessionClientID();
};

export const getSessionClientID = () => {
  const userAgent = window.navigator.userAgent;
  const getClientIP = sessionStorage.getItem('client_ip');
  const getUser = sessionStorage.getItem('user');
  const clientIP = getClientIP ? JSON.parse(getClientIP) : null;
  const sessionID = getUser ? JSON.parse(getUser).session_id : null;

  const clientID = encryptData(
    `${userAgent}/${clientIP.ipv4}${sessionID ? '/' + sessionID : ''}`,
  );

  return clientID;
};
