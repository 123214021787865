import { notification } from 'antd';
import axios from 'axios';

import { decryptData } from '../utils/api';

const Axios = axios.create({});

// Add a request interceptor
Axios.interceptors.request.use(
  async (config) => {
    // Do something before request is sent
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
Axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    return response;
  },
  (error) => {
    let errorDescription;

    const responseError = decryptData(error?.response?.data);

    if (responseError) {
      errorDescription = responseError;
    }

    // Log out user
    if (error.response.status === 401 || error.response.status === 403) {
      // Display error message for status code 403
      notification.error({
        message: 'Error',
        // @ts-ignore
        description: errorDescription,
        duration: 10,
      });

      sessionStorage.clear();
      window.location.href = '/';

      return Promise.reject(error);
    }

    return Promise.reject(error);
  },
);

export default Axios;
