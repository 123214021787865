import './styles/styles.scss';

import { ConfigProvider } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import Startup from './features/startup/Startup';
import { store } from './store';

const container = document.getElementById('root') as HTMLElement;

const root = ReactDOM.createRoot(container);

root.render(
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#00b2a3',
        fontSize: 16,
        fontFamily: `'Didact Gothic', 'Open Sans', sans-serif`,
      },
    }}
  >
    <Provider store={store}>
      <BrowserRouter>
        <Startup />
      </BrowserRouter>
    </Provider>
  </ConfigProvider>,
);
